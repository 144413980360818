import {React} from 'react';
import '../styles/Main.css'
import { Link } from 'react-router-dom';
import Test from './subcomponents/Test';
import Partner from './subcomponents/Partner';
import Prize from './subcomponents/Prize';
import Contact from './subcomponents/Contact'
import ftz from '../images/ftz.svg'
import ftz_en from '../images/ftz_en.svg'
import { useEffect,useState} from 'react';
import ArrowUpwardOutlinedIcon from '@mui/icons-material/ArrowUpwardOutlined';
import {FormattedMessage,useIntl} from 'react-intl';
import rus from '../images/lang-ru.png'
import eng from '../images/lang-en.png'
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import Card from '@mui/material/Card';
import { CardActions } from '@mui/material';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import { site_address } from '../settings';
import moment from 'moment';


const Main = () => {
    const intl = useIntl()
    const [showButton, setShowButton] = useState(false);
    useEffect(() => {
        window.addEventListener("scroll", () => {
          if (window.pageYOffset > 1250) {
            setShowButton(true);
          } else {
            setShowButton(false);
          }
        });
      }, [])
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    } 
    const transreload = (locale) => {
        localStorage.setItem('locale',locale)
        window.location.reload()
    }
    const responsive = {
        desktop: {
          breakpoint: {
            max: 3000,
            min: 1024
          },
          items: 3,
          partialVisibilityGutter: 0
        },
        mobile: {
          breakpoint: {
            max: 464,
            min: 0
          },
          items: 0.7,
          partialVisibilityGutter: 0
        },
        tablet: {
          breakpoint: {
            max: 1024,
            min: 464
          },
          items: 1,
          partialVisibilityGutter: 0
        },
    }
    return (
        <div className='main'>
            <div className='main-top' id='main-container'>
                {showButton && (
                    <div onClick={scrollToTop} className="back-to-top">
                        <a rel="noreferrer"><ArrowUpwardOutlinedIcon sx={{fontSize:50,color:'white'}}/></a>
                    </div>
                )}
                <div className='main-header'>
                    <div className='main-header-head'>
                        {localStorage.getItem('locale')=='ru-RU'||localStorage.getItem('locale')==null?
                            (
                            <div className='main-header-language' onClick={()=>transreload('en-US')}>
                                <img className='lang-img' src={eng} alt=""/>
                            </div>
                            ):(
                            <div className='main-header-language' onClick={()=>transreload('ru-RU')}>
                                <img className='lang-img' src={rus} alt=""/>
                            </div>)}
                        <div className='main-header-auth'>
                            <Link to='/login' className='auth-button'>
                                <button className='auth-button'><FormattedMessage id="main_auth_btn"/></button>
                            </Link>
                            {/* <Link to='/registration' className='auth-button'>
                                <button className='auth-button'>Регистрация</button>
                            </Link> */}
                        </div>
                    </div>
                    <div className='main-header-nav'>
                        <div className='main-header-link'>
                            <Link className='link' to="/about"><FormattedMessage id="main_link_about"/></Link>
                            <Link className='link' to="/news/"><FormattedMessage id="main_link_news"/></Link>
                            <a className='link' href='#test' rel="noreferrer"><FormattedMessage id="main_link_test"/></a>
                            <a className='link' href='#partner' rel="noreferrer" ><FormattedMessage id="main_link_partner"/></a>
                            <a className='link' href='#contact' rel="noreferrer"><FormattedMessage id="main_link_contacts"/></a>
                            <Link className='link' to="/documents"><FormattedMessage id="main_link_documents"/></Link>
                        </div>
                    </div>
                </div>
                <div className='main-part'>
                    <div className='main-logo'><div className='imag'><img src={localStorage.getItem('locale')=='ru-RU'?ftz:ftz_en} alt=""/></div></div>
                    <div className='main-title'> <div className='title-text'><FormattedMessage id="main_logo_title"/></div></div>
                    <div className='main-text'><FormattedMessage id="main_logo_subtitle"/></div>
                    <div className='itstars'><div className='itst'><img src={require('../images/Itstars.png')} alt=""/></div></div>
                    <div className='mercury'><div className='merc'><img src={require('../images/mercury.png')} alt=""/></div></div>
                    {(document.documentElement.scrollWidth>500)?(<div className='version'>
                        <FormattedMessage id="main_version"/><br/>
                        <FormattedMessage id="main_update"/><br/>
                        <FormattedMessage id="main_registr"/><br/>
                        <FormattedMessage id="main_entry"/><br/>
                    </div>
                    ):(
                        <div className='version'>
                        <FormattedMessage id="main_version"/>{". "} 
                        <FormattedMessage id="main_update"/><br/>
                        <FormattedMessage id="main_registr"/>{". "} 
                        <FormattedMessage id="main_entry"/><br/>
                    </div>)}
                </div>
            </div>
            <div className='main-news1'>
                <div className='news-title1'><Link to='/news/'><FormattedMessage id="main_news_title"/></Link></div>
                <Carousel
                    responsive={responsive}
                    removeArrowOnDeviceType={["tablet", "mobile"]}
                    infinite={false}
                    arrows
                    centerMode
                    containerClass="container"
                    slidesToSlide={2}
                    style={{justifyContent: 'center'}}
                    >
                    <Card sx={{ width: '85%' }} style={{width: '85%',minWidth: 100,height:400,minHeight: 100,backgroundColor:'white',borderRadius:10,display:'flex',flexDirection:'column',justifyContent:'space-between',boxSizing:'border-box'}}>
                        <CardActionArea onClick={()=>window.location.assign(site_address+'/news/40')} style={{maxHeight:'75%',boxSizing:'border-box'}}>
                            <CardMedia
                            component="img"
                            height="140"
                            image={require('../images/news/news-40.JPG')}
                            alt="green iguana"
                            style={{height: 'auto',maxHeight:'100%',width:'100%'}}
                            />
                            <CardContent style={{height: 'auto',padding:'15px 15px 0px 15px'}}>
                                <Typography variant="body2" color="rgba(0, 0, 0, 0.8)">
                                    {intl.formatMessage({id:'news_40_main_title'})}
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                        <CardActions style={{padding:'14px 20px 20px 10px',display:'flex',alignItems:'center',justifyContent:'space-between',boxSizing:'border-box',height:'auto'}}>
                            <div className='card-date'>
                                09.12.2024
                            </div>
                            <div className='card-type'>
                                {intl.formatMessage({id:'news_press_release'})}
                            </div>
                        </CardActions>
                    </Card>
                    <Card sx={{ width: '85%' }} style={{width: '85%',minWidth: 100,height:400,minHeight: 100,backgroundColor:'white',borderRadius:10,display:'flex',flexDirection:'column',justifyContent:'space-between',boxSizing:'border-box'}}>
                        <CardActionArea onClick={()=>window.location.assign(site_address+'/news/39')} style={{maxHeight:'75%',boxSizing:'border-box'}}>
                            <CardMedia
                            component="img"
                            height="140"
                            image={require('../images/news/news-39.jpg')}
                            alt="green iguana"
                            style={{height: 'auto',maxHeight:'100%',width:'100%'}}
                            />
                            <CardContent style={{height: 'auto',padding:'15px 15px 0px 15px'}}>
                                <Typography variant="body2" color="rgba(0, 0, 0, 0.8)">
                                    {intl.formatMessage({id:'news_39_main_title'})}
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                        <CardActions style={{padding:'14px 20px 20px 10px',display:'flex',alignItems:'center',justifyContent:'space-between',boxSizing:'border-box',height:'auto'}}>
                            <div className='card-date'>
                                11.10.2024
                            </div>
                            <div className='card-type'>
                                {intl.formatMessage({id:'news_press_release'})}
                            </div>
                        </CardActions>
                    </Card>
                    <Card sx={{ width: '85%' }} style={{width: '85%',minWidth: 100,height:400,minHeight: 100,backgroundColor:'white',borderRadius:10,display:'flex',flexDirection:'column',justifyContent:'space-between',boxSizing:'border-box'}}>
                        <CardActionArea onClick={()=>window.location.assign(site_address+'/news/38')} style={{maxHeight:'75%',boxSizing:'border-box'}}>
                            <CardMedia
                            component="img"
                            height="140"
                            image={require('../images/news/news-38.png')}
                            alt="green iguana"
                            style={{height: 'auto',maxHeight:'100%',width:'100%'}}
                            />
                            <CardContent style={{height: 'auto',padding:'15px 15px 0px 15px'}}>
                                <Typography variant="body2" color="rgba(0, 0, 0, 0.8)">
                                    {intl.formatMessage({id:'news_38_main_title'})}
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                        <CardActions style={{padding:'14px 20px 20px 10px',display:'flex',alignItems:'center',justifyContent:'space-between',boxSizing:'border-box',height:'auto'}}>
                            <div className='card-date'>
                                13.09.2024
                            </div>
                            <div className='card-type'>
                                {intl.formatMessage({id:'news_press_release'})}
                            </div>
                        </CardActions>
                    </Card>
                    <Card sx={{ width: '85%' }} style={{width: '85%',minWidth: 100,height:400,minHeight: 100,backgroundColor:'white',borderRadius:10,display:'flex',flexDirection:'column',justifyContent:'space-between',boxSizing:'border-box'}}>
                        <CardActionArea onClick={()=>window.location.assign(site_address+'/news/37')} style={{maxHeight:'75%',boxSizing:'border-box'}}>
                            <CardMedia
                            component="img"
                            height="140"
                            image={require('../images/news/news-37.png')}
                            alt="green iguana"
                            style={{height: 'auto',maxHeight:'100%',width:'100%'}}
                            />
                            <CardContent style={{height: 'auto',padding:'15px 15px 0px 15px'}}>
                                <Typography variant="body2" color="rgba(0, 0, 0, 0.8)">
                                    {intl.formatMessage({id:'news_37_main_title'})}
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                        <CardActions style={{padding:'14px 20px 20px 10px',display:'flex',alignItems:'center',justifyContent:'space-between',boxSizing:'border-box',height:'auto'}}>
                            <div className='card-date'>
                                20.08.2024
                            </div>
                            <div className='card-type'>
                                {intl.formatMessage({id:'news_press_release'})}
                            </div>
                        </CardActions>
                    </Card>
                    <Card sx={{ width: '85%' }} style={{width: '85%',minWidth: 100,height:400,minHeight: 100,backgroundColor:'white',borderRadius:10,display:'flex',flexDirection:'column',justifyContent:'space-between',boxSizing:'border-box'}}>
                        <CardActionArea onClick={()=>window.location.assign(site_address+'/news/36')} style={{maxHeight:'75%',boxSizing:'border-box'}}>
                            <CardMedia
                            component="img"
                            height="140"
                            image={require('../images/news/news-36.png')}
                            alt="green iguana"
                            style={{height: 'auto',maxHeight:'100%',width:'100%'}}
                            />
                            <CardContent style={{height: 'auto',padding:'15px 15px 0px 15px'}}>
                                <Typography variant="body2" color="rgba(0, 0, 0, 0.8)">
                                    {intl.formatMessage({id:'news_36_main_title'})}
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                        <CardActions style={{padding:'14px 20px 20px 10px',display:'flex',alignItems:'center',justifyContent:'space-between',boxSizing:'border-box',height:'auto'}}>
                            <div className='card-date'>
                                30.05.2024
                            </div>
                            <div className='card-type'>
                                {intl.formatMessage({id:'news_press_release'})}
                            </div>
                        </CardActions>
                    </Card>
                    <Card sx={{ width: '85%' }} style={{width: '85%',minWidth: 100,height:400,minHeight: 100,backgroundColor:'white',borderRadius:10,display:'flex',flexDirection:'column',justifyContent:'space-between',boxSizing:'border-box'}}>
                        <CardActionArea onClick={()=>window.location.assign(site_address+'/news/35')} style={{maxHeight:'75%',boxSizing:'border-box'}}>
                            <CardMedia
                            component="img"
                            height="140"
                            image={require('../images/news/news-35.png')}
                            alt="green iguana"
                            style={{height: 'auto',maxHeight:'100%',width:'100%'}}
                            />
                            <CardContent style={{height: 'auto',padding:'15px 15px 0px 15px'}}>
                                <Typography variant="body2" color="rgba(0, 0, 0, 0.8)">
                                    {intl.formatMessage({id:'news_35_main_title'})}
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                        <CardActions style={{padding:'14px 20px 20px 10px',display:'flex',alignItems:'center',justifyContent:'space-between',boxSizing:'border-box',height:'auto'}}>
                            <div className='card-date'>
                                17.04.2024
                            </div>
                            <div className='card-type'>
                                {intl.formatMessage({id:'news_press_release'})}
                            </div>
                        </CardActions>
                    </Card>
                    <Card sx={{ width: '85%' }} style={{width: '85%',minWidth: 100,height:400,minHeight: 100,backgroundColor:'white',borderRadius:10,display:'flex',flexDirection:'column',justifyContent:'space-between',boxSizing:'border-box'}}>
                        <CardActionArea onClick={()=>window.location.assign(site_address+'/news/34')} style={{maxHeight:'75%',boxSizing:'border-box'}}>
                            <CardMedia
                            component="img"
                            height="140"
                            image={require('../images/news/news-34.png')}
                            alt="green iguana"
                            style={{height: 'auto',maxHeight:'100%',width:'100%'}}
                            />
                            <CardContent style={{height: 'auto',padding:'15px 15px 0px 15px'}}>
                                <Typography variant="body2" color="rgba(0, 0, 0, 0.8)">
                                    {intl.formatMessage({id:'news_34_main_title'})}
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                        <CardActions style={{padding:'14px 20px 20px 10px',display:'flex',alignItems:'center',justifyContent:'space-between',boxSizing:'border-box',height:'auto'}}>
                            <div className='card-date'>
                                08.04.2024
                            </div>
                            <div className='card-type'>
                                {intl.formatMessage({id:'news_press_release'})}
                            </div>
                        </CardActions>
                    </Card>
                    <Card sx={{ width: '85%' }} style={{width: '85%',minWidth: 100,height:400,minHeight: 100,backgroundColor:'white',borderRadius:10,display:'flex',flexDirection:'column',justifyContent:'space-between',boxSizing:'border-box'}}>
                        <CardActionArea onClick={()=>window.location.assign(site_address+'/news/33')} style={{maxHeight:'75%',boxSizing:'border-box'}}>
                            <CardMedia
                            component="img"
                            height="140"
                            image={require('../images/news/news-33.png')}
                            alt="green iguana"
                            style={{height: 'auto',maxHeight:'100%',width:'100%'}}
                            />
                            <CardContent style={{height: 'auto',padding:'15px 15px 0px 15px'}}>
                                <Typography variant="body2" color="rgba(0, 0, 0, 0.8)">
                                    {intl.formatMessage({id:'news_33_main_title'})}
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                        <CardActions style={{padding:'14px 20px 20px 10px',display:'flex',alignItems:'center',justifyContent:'space-between',boxSizing:'border-box',height:'auto'}}>
                            <div className='card-date'>
                                09.02.2024
                            </div>
                            <div className='card-type'>
                                {intl.formatMessage({id:'news_press_release'})}
                            </div>
                        </CardActions>
                    </Card>
                    <Card sx={{ width: '85%' }} style={{width: '85%',minWidth: 100,height:400,minHeight: 100,backgroundColor:'white',borderRadius:10,display:'flex',flexDirection:'column',justifyContent:'space-between',boxSizing:'border-box'}}>
                        <CardActionArea onClick={()=>window.location.assign(site_address+'/news/32')} style={{maxHeight:'75%',boxSizing:'border-box'}}>
                            <CardMedia
                            component="img"
                            height="140"
                            image={require('../images/news/news-32.png')}
                            alt="green iguana"
                            style={{height: 'auto',maxHeight:'100%',width:'100%'}}
                            />
                            <CardContent style={{height: 'auto',padding:'15px 15px 0px 15px'}}>
                                <Typography variant="body2" color="rgba(0, 0, 0, 0.8)">
                                    {intl.formatMessage({id:'news_32_main_title'})}
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                        <CardActions style={{padding:'14px 20px 20px 10px',display:'flex',alignItems:'center',justifyContent:'space-between',boxSizing:'border-box',height:'auto'}}>
                            <div className='card-date'>
                                26.12.2023
                            </div>
                            <div className='card-type'>
                                {intl.formatMessage({id:'news_press_release'})}
                            </div>
                        </CardActions>
                    </Card>
                    <Card sx={{ width: '85%' }} style={{width: '85%',minWidth: 100,height:400,minHeight: 100,backgroundColor:'white',borderRadius:10,display:'flex',flexDirection:'column',justifyContent:'space-between',boxSizing:'border-box'}}>
                        <CardActionArea onClick={()=>window.location.assign(site_address+'/news/31')} style={{maxHeight:'75%',boxSizing:'border-box'}}>
                            <CardMedia
                            component="img"
                            height="140"
                            image={require('../images/news/news-31.png')}
                            alt="green iguana"
                            style={{height: 'auto',maxHeight:'100%',width:'100%'}}
                            />
                            <CardContent style={{height: 'auto',padding:'15px 15px 0px 15px'}}>
                                <Typography variant="body2" color="rgba(0, 0, 0, 0.8)">
                                    {intl.formatMessage({id:'news_31_main_title'})}
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                        <CardActions style={{padding:'14px 20px 20px 10px',display:'flex',alignItems:'center',justifyContent:'space-between',boxSizing:'border-box',height:'auto'}}>
                            <div className='card-date'>
                                21.12.2023
                            </div>
                            <div className='card-type'>
                                {intl.formatMessage({id:'news_press_release'})}
                            </div>
                        </CardActions>
                    </Card>
                    <Card sx={{ width: '85%' }} style={{width: '85%',minWidth: 100,height:400,minHeight: 100,backgroundColor:'white',borderRadius:10,display:'flex',flexDirection:'column',justifyContent:'space-between',boxSizing:'border-box'}}>
                        <CardActionArea onClick={()=>window.location.assign(site_address+'/news/30')} style={{maxHeight:'75%',boxSizing:'border-box'}}>
                            <CardMedia
                            component="img"
                            height="140"
                            image={require('../images/news/news-30.png')}
                            alt="green iguana"
                            style={{height: 'auto',maxHeight:'100%',width:'100%'}}
                            />
                            <CardContent style={{height: 'auto',padding:'15px 15px 0px 15px'}}>
                                <Typography variant="body2" color="rgba(0, 0, 0, 0.8)">
                                    {intl.formatMessage({id:'news_30_main_title'})}
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                        <CardActions style={{padding:'14px 20px 20px 10px',display:'flex',alignItems:'center',justifyContent:'space-between',boxSizing:'border-box',height:'auto'}}>
                            <div className='card-date'>
                                08.12.2023
                            </div>
                            <div className='card-type'>
                                {intl.formatMessage({id:'news_press_release'})}
                            </div>
                        </CardActions>
                    </Card>
                    <Card sx={{ width: '85%' }} style={{width: '85%',minWidth: 100,height:400,minHeight: 100,backgroundColor:'white',borderRadius:10,display:'flex',flexDirection:'column',justifyContent:'space-between',boxSizing:'border-box'}}>
                        <CardActionArea onClick={()=>window.location.assign(site_address+'/news/29')} style={{maxHeight:'75%',boxSizing:'border-box'}}>
                            <CardMedia
                            component="img"
                            height="140"
                            image={require('../images/news/news-29.png')}
                            alt="green iguana"
                            style={{height: 'auto',maxHeight:'100%',width:'100%'}}
                            />
                            <CardContent style={{height: 'auto',padding:'15px 15px 0px 15px'}}>
                                <Typography variant="body2" color="rgba(0, 0, 0, 0.8)">
                                    {intl.formatMessage({id:'news_29_main_title'})}
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                        <CardActions style={{padding:'14px 20px 20px 10px',display:'flex',alignItems:'center',justifyContent:'space-between',boxSizing:'border-box',height:'auto'}}>
                            <div className='card-date'>
                                28.11.2023
                            </div>
                            <div className='card-type'>
                                {intl.formatMessage({id:'news_press_release'})}
                            </div>
                        </CardActions>
                    </Card>
                    <Card sx={{ width: '85%' }} style={{width: '85%',minWidth: 100,height:400,minHeight: 100,backgroundColor:'white',borderRadius:10,display:'flex',flexDirection:'column',justifyContent:'space-between',boxSizing:'border-box'}}>
                        <CardActionArea onClick={()=>window.location.assign(site_address+'/news/28')} style={{maxHeight:'75%',boxSizing:'border-box'}}>
                            <CardMedia
                            component="img"
                            height="140"
                            image={require('../images/news/news-28.png')}
                            alt="green iguana"
                            style={{height: 'auto',maxHeight:'100%',width:'100%'}}
                            />
                            <CardContent style={{height: 'auto',padding:'15px 15px 0px 15px'}}>
                                <Typography variant="body2" color="rgba(0, 0, 0, 0.8)">
                                    {intl.formatMessage({id:'news_28_main_title'})}
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                        <CardActions style={{padding:'14px 20px 20px 10px',display:'flex',alignItems:'center',justifyContent:'space-between',boxSizing:'border-box',height:'auto'}}>
                            <div className='card-date'>
                                16.10.2023
                            </div>
                            <div className='card-type'>
                                {intl.formatMessage({id:'news_press_release'})}
                            </div>
                        </CardActions>
                    </Card>
                    <Card sx={{ width: '85%' }} style={{width: '85%',minWidth: 100,height:400,minHeight: 100,backgroundColor:'white',borderRadius:10,display:'flex',flexDirection:'column',justifyContent:'space-between',boxSizing:'border-box'}}>
                        <CardActionArea onClick={()=>window.location.assign(site_address+'/news/27')} style={{maxHeight:'75%',boxSizing:'border-box'}}>
                            <CardMedia
                            component="img"
                            height="140"
                            image={require('../images/news/news-27.png')}
                            alt="green iguana"
                            style={{height: 'auto',maxHeight:'100%',width:'100%'}}
                            />
                            <CardContent style={{height: 'auto',padding:'15px 15px 0px 15px'}}>
                                <Typography variant="body2" color="rgba(0, 0, 0, 0.8)">
                                    {intl.formatMessage({id:'news_27_main_title'})}
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                        <CardActions style={{padding:'14px 20px 20px 10px',display:'flex',alignItems:'center',justifyContent:'space-between',boxSizing:'border-box',height:'auto'}}>
                            <div className='card-date'>
                                06.10.2023
                            </div>
                            <div className='card-type'>
                                {intl.formatMessage({id:'news_press_release'})}
                            </div>
                        </CardActions>
                    </Card>
                    <Card sx={{ width: '85%' }} style={{width: '85%',minWidth: 100,height:400,minHeight: 100,backgroundColor:'white',borderRadius:10,display:'flex',flexDirection:'column',justifyContent:'space-between',boxSizing:'border-box'}}>
                        <CardActionArea onClick={()=>window.location.assign(site_address+'/news/26')} style={{maxHeight:'75%',boxSizing:'border-box'}}>
                            <CardMedia
                            component="img"
                            height="140"
                            image={require('../images/news/news-26.png')}
                            alt="green iguana"
                            style={{height: 'auto',maxHeight:'100%',width:'100%'}}
                            />
                            <CardContent style={{height: 'auto',padding:'15px 15px 0px 15px'}}>
                                <Typography variant="body2" color="rgba(0, 0, 0, 0.8)">
                                    {intl.formatMessage({id:'news_26_main_title'})}
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                        <CardActions style={{padding:'14px 20px 20px 10px',display:'flex',alignItems:'center',justifyContent:'space-between',boxSizing:'border-box',height:'auto'}}>
                            <div className='card-date'>
                                06.10.2023
                            </div>
                            <div className='card-type'>
                                {intl.formatMessage({id:'news_press_release'})}
                            </div>
                        </CardActions>
                    </Card>
                    <Card sx={{ width: '85%' }} style={{width: '85%',minWidth: 100,height:400,minHeight: 100,backgroundColor:'white',borderRadius:10,display:'flex',flexDirection:'column',justifyContent:'space-between',boxSizing:'border-box'}}>
                        <CardActionArea onClick={()=>window.location.assign(site_address+'/news/25')} style={{maxHeight:'75%',boxSizing:'border-box'}}>
                            <CardMedia
                            component="img"
                            height="140"
                            image={require('../images/news/news-25.png')}
                            alt="green iguana"
                            style={{height: 'auto',maxHeight:'100%',width:'100%'}}
                            />
                            <CardContent style={{height: 'auto',padding:'15px 15px 0px 15px'}}>
                                <Typography variant="body2" color="rgba(0, 0, 0, 0.8)">
                                    {intl.formatMessage({id:'news_25_main_title'})}
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                        <CardActions style={{padding:'14px 20px 20px 10px',display:'flex',alignItems:'center',justifyContent:'space-between',boxSizing:'border-box',height:'auto'}}>
                            <div className='card-date'>
                                23.09.2023
                            </div>
                            <div className='card-type'>
                                {intl.formatMessage({id:'news_press_release'})}
                            </div>
                        </CardActions>
                    </Card>
                    <Card sx={{ width: '85%' }} style={{width: '85%',minWidth: 100,height:400,minHeight: 100,backgroundColor:'white',borderRadius:10,display:'flex',flexDirection:'column',justifyContent:'space-between',boxSizing:'border-box'}}>
                        <CardActionArea onClick={()=>window.location.assign(site_address+'/news/24')} style={{maxHeight:'75%',boxSizing:'border-box'}}>
                            <CardMedia
                            component="img"
                            height="140"
                            image={require('../images/news/news-24.png')}
                            alt="green iguana"
                            style={{height: 'auto',maxHeight:'100%',width:'100%'}}
                            />
                            <CardContent style={{height: 'auto',padding:'15px 15px 0px 15px'}}>
                                <Typography variant="body2" color="rgba(0, 0, 0, 0.8)">
                                    {intl.formatMessage({id:'news_24_main_title'})}
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                        <CardActions style={{padding:'14px 20px 20px 10px',display:'flex',alignItems:'center',justifyContent:'space-between',boxSizing:'border-box',height:'auto'}}>
                            <div className='card-date'>
                                23.09.2023
                            </div>
                            <div className='card-type'>
                                {intl.formatMessage({id:'news_press_release'})}
                            </div>
                        </CardActions>
                    </Card>
                    <Card sx={{ width: '85%' }} style={{width: '85%',minWidth: 100,height:400,minHeight: 100,backgroundColor:'white',borderRadius:10,display:'flex',flexDirection:'column',justifyContent:'space-between',boxSizing:'border-box'}}>
                        <CardActionArea onClick={()=>window.location.assign(site_address+'/news/23')} style={{maxHeight:'75%',boxSizing:'border-box'}}>
                            <CardMedia
                            component="img"
                            height="140"
                            image={require('../images/news/news-23.png')}
                            alt="green iguana"
                            style={{height: 'auto',maxHeight:'100%',width:'100%'}}
                            />
                            <CardContent style={{height: 'auto',padding:'15px 15px 0px 15px'}}>
                                <Typography variant="body2" color="rgba(0, 0, 0, 0.8)">
                                    {intl.formatMessage({id:'news_23_main_title'})}
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                        <CardActions style={{padding:'14px 20px 20px 10px',display:'flex',alignItems:'center',justifyContent:'space-between',boxSizing:'border-box',height:'auto'}}>
                            <div className='card-date'>
                                19.09.2023
                            </div>
                            <div className='card-type'>
                                {intl.formatMessage({id:'news_press_release'})}
                            </div>
                        </CardActions>
                    </Card>
                </Carousel>
            </div>
            <div className='main-test' id='test'>
                <div className='test-content'>
                    <div className='test-title'><FormattedMessage id="main_test_title"/></div>
                    <Test text={intl.formatMessage({id:'main_test_1_text'})} image='test-img-1.png' id="test-1" identif='1'/>
                    <Test text={intl.formatMessage({id:'main_test_2_text'})} image='test-img-2.png' id="test-2" identif='2'/>
                    <Test text={intl.formatMessage({id:'main_test_3_text'})} image='test-img-3.png' id="test-3" identif='3'/>
                    <Test text={intl.formatMessage({id:'main_test_4_text'})} image='test-img-4.png' id="test-4" identif='4'/>
                    <Test text={intl.formatMessage({id:'main_test_5_text'})} image='test-img-5.png' id="test-5" identif='5'/> 
                </div>
            </div>
            <div className='main-partner' id='partner'>   
                <div className='partner-content'>
                    <div className='partner-title'><FormattedMessage id="main_partner_title"/></div>
                    <Partner text = {intl.formatMessage({id:'main_partner_1_title'})} id="partner-1" img ="radiology.png" link = "https://telemedai.ru/"/>
                    <Partner text = {intl.formatMessage({id:'main_partner_2_title'})} id="partner-2" img ="skolkovo.png" link = "https://sk.ru/%0A"/>
                    <Partner text = {intl.formatMessage({id:'main_partner_3_title'})} id="partner-3" img ="skolkovo.png" link = "https://pulmonology-fmba.ru/"/>
                    <Partner text = {intl.formatMessage({id:'main_partner_4_title'})} id="partner-4" img ="minzdrav.png" link = "https://rkpd.tatarstan.ru/"/>
                    <Partner text = {intl.formatMessage({id:'main_partner_5_title'})} id="partner-5" img ="bortnik.png" link = "https://fasie.ru/"/>
                    <Partner text = {intl.formatMessage({id:'main_partner_6_title'})} id="partner-6" img ="NMRC.jpg" link = "https://nmrc.ru/"/>
                    <Partner text = {intl.formatMessage({id:'main_partner_7_title'})} id="partner-7" img ="bortnik.png" link = "https://www.msu.ru/index.php"/>
                </div>
            </div>
            <div className='main-prize' id='prize'>
                <div className='prize-content'>
                    <div className='prize-title'><FormattedMessage id="main_awards_title"/></div>
                    <Prize id="prize-1" text={intl.formatMessage({id:'main_awards_1_text'})} img ="Itstars.png"/>
                    <Prize id="prize-2" text={intl.formatMessage({id:'main_awards_2_text'})} img ="mercury.png"/>
                </div>
            </div>
            <div className='main-contact' id='contact'>
                <div className='contact-content'>
                    <div className='contact-content-title'><FormattedMessage id="main_contacts_title"/></div>
                    <Contact id="contact-1" title = {intl.formatMessage({id:'main_contacts_address_title'})} text={intl.formatMessage({id:'main_contacts_address_text'})} component="TelegramIcon"/>
                    <Contact id="contact-2" title= {intl.formatMessage({id:'main_contacts_phone_title'})} text="+7(84342) 5 55 55" component="TelegramIcon"/>
                    <Contact id="contact-3" title = 'E-MAIL:' text='info@ftizisbiomed.ru' component="TelegramIcon"/>
                </div>
                <div className='ftizis-info'>© {moment().format('YYYY')}<FormattedMessage id="main_ftizisbiomed"/></div>
                <div className='social'>
                    <a href='https://vk.com/club214630195' target="_blank" rel="noreferrer"><img className='soc-img' src = {require('./subcomponents/img/vk.png')} alt=""/></a>
                    <a href='https://t.me/FtizisBioMed' target="_blank" rel="noreferrer"><img className='soc-img' src = {require('./subcomponents/img/tel.png')} alt=""/></a>
                </div>
                <div className='vector-info'><FormattedMessage id="main_vector"/></div>
                <div className='rusprofile1'>ИНН / ОГРН : 1652023394 / 1151677001486 </div>
                {/* <div className='rusprofile'>
                    <a href={require('../video/pdf.pdf')} target='_blank'>Публичная оферта</a>
                </div> */}
            </div>
        </div>
    );
}   

export default Main;